import React from 'react'
import PropTypes from 'prop-types'
import LinkFormatter from 'components/LinkFormatter'
import NewsletterForm from 'components/NewsletterForm'
import { FooterContainerMain, FooterColumn, FooterItemBody } from './styles'

const FooterContent = ({ query }) => {
  return query?.footer_items?.length > 0 ? (
    <FooterContainerMain>
      <FooterColumn>
        {query?.footer_items.map((item, index) => {
          return item.footer_item_link?.uid && item.footer_item_label?.text ? (
            <LinkFormatter
              pageHandle={`/${item.footer_item_link?.uid}`}
              pageType={item?.page_type}
              key={index}
            >
              <FooterItemBody whiteTitle type="h5-secondary" as="h5">
                {item.footer_item_label?.text}
              </FooterItemBody>
            </LinkFormatter>
          ) : (
            <></>
          )
        })}
        {query?.newsletter_input_component ? (
          <NewsletterForm query={query?.newsletter_input_component} />
        ) : (
          <></>
        )}
      </FooterColumn>
    </FooterContainerMain>
  ) : (
    <></>
  )
}

FooterContent.propTypes = {
  query: PropTypes.object.isRequired,
}

export default FooterContent
