import styled from '@emotion/styled'

export const FooterContainerStyled = styled.footer`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 2000px;
  display: flex;
  flex-direction: column;
  position: relative;

  .Navigation__Logo {
    width: 173px;
    height: 103px;
    position: absolute;
    top: 0;
    left: 15px;

    path {
      fill: white;
    }

    @media (min-width: ${props => props.theme.breakpoints.l}) {
      left: 35px;
      width: 312px;
      height: 186px;
    }
  }
`

export const FooterBackground = styled.div`
  background-color: ${props => props.theme.colors.primarygreen};
  z-index: 1;
  width: 100%;
  min-height: 573px;
`

export const PSBIconContainer = styled.div`
  position: absolute;
  right: 100px;
  top: 200px;
`
