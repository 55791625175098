import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import LocaleContext from 'context/LocaleProvider'
import { Desktop } from 'components/Utilities/Media'
import LegalFooter from 'components/LegalFooter'
import Logo from 'components/Logo'
import Icon from 'components/Icon'
import ScrollToTop from 'components/ScrollToTop'
import FooterContent from './FooterContent'
import {
  FooterContainerStyled,
  PSBIconContainer,
  FooterBackground,
} from './styles'
import { theme } from 'styles'

const Footer = () => {
  const lang = useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const footerQuery = useStaticQuery(graphql`
    {
      footer: allPrismicFooter {
        nodes {
          lang
          data {
            footer_items {
              page_type
              footer_item_link {
                uid
              }
              footer_item_label {
                text
              }
            }
            newsletter_input_component {
              document {
                ... on PrismicNewsletterInput {
                  id
                  data {
                    input_success_message {
                      text
                    }
                    input_placeholder {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
      legalFooter: allPrismicLegalFooter {
        nodes {
          lang
          data {
            legal_footer_items {
              page_type
              legal_item_label {
                text
              }
              legal_item_link {
                uid
              }
            }
            copyright_text {
              copyright {
                text
              }
            }
          }
        }
      }
    }
  `)

  const footer = footerQuery.footer.nodes
    .filter(node => node.lang === i18n.locale)
    .map(r => r.data)

  const legalFooter = footerQuery.legalFooter.nodes
    .filter(node => node.lang === i18n.locale)
    .map(r => r.data)

  return (
    <FooterBackground>
      <FooterContainerStyled>
        <Logo className="Navigation__Logo" LogoFill={theme.colors.lightblue} />
        <FooterContent query={footer?.[0]} />
        <Desktop>
          <PSBIconContainer className="Icon__PSBSymbol">
            <Icon type="psb-symbol" />
          </PSBIconContainer>
        </Desktop>
        <LegalFooter query={legalFooter?.[0]} />
        <ScrollToTop />
      </FooterContainerStyled>
    </FooterBackground>
  )
}

export default Footer
