import styled from '@emotion/styled'
import Text from 'components/Utilities/Text'

export const LegalFooterStyled = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.primarygreen};
  display: flex;
  flex-direction: column;

  p,
  a {
    color: ${props => props.theme.colors.lightblue};
    font-weight: 100;
    text-decoration: none;
    white-space: nowrap;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    flex-direction: row;
  }

`

export const LegalFooterLinks = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  a {
    @media (min-width: ${props => props.theme.breakpoints.l}) {
      margin-right: 80px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 40px 20px;
    justify-content: space-between;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding-left:180px;
    margin: 0 auto;
    justify-content: center;
  }
`

export const StyledText = styled(Text)``

export const CopyrightLeftContainer = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 20px 20px 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    position: absolute;
    left: 80px;
  }
`

export const CopyrightRightContainer = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 30px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    position: absolute;
    right: 80px;
  }
`
