import React from 'react'
import { ScrollToTopContainer } from './styles'

const ScrollToTop = props => {
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <ScrollToTopContainer>
      <div className="scroll-to-top">
        <div onClick={scrollToTop}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
          >
            <g fill="none" fillRule="evenodd">
              <g>
                <g>
                  <path
                    fill="#043022"
                    d="M0 0H100V100H0z"
                    transform="translate(-1580 -4276) translate(1580 4276)"
                  />
                  <g stroke="#FFF" strokeLinecap="square">
                    <g>
                      <path
                        d="M53 23L31 0zM53 23L31 46M0 23L36 23"
                        transform="translate(-1580 -4276) translate(1580 4276) rotate(-90 52 24.5) translate(.5 .5)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </ScrollToTopContainer>
  )
}

export default ScrollToTop
