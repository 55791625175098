import styled from '@emotion/styled'
import Text from 'components/Utilities/Text'

export const StyledInputContainer = styled.input`
  font-family: ${props => props.theme.fontFamily.primary};
  background-color: transparent;
  border-bottom: 1px solid ${props => props.theme.colors.lightblue};
  font-weight: 100;
  color: ${props => props.theme.colors.lightblue};
  padding-right: 20px;
  font-size: ${props => props.theme.fonts.s};
  min-width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.l_secondary};
    min-width: auto;
  }

  &::placeholder {
    color: ${props => props.theme.colors.lightblue};
    opacity: 1;
  }

  &::value {
    color: red;
  }

  &:focus {
    outline: 0;
  }

  &:selected {
    outline: 0;
    background-color: transparent;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: ${props => props.theme.colors.white};
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.lightblue};
  }
`

export const InputContainer = styled.form`
  display: inline-flex;

  .arrow-right {
    margin-left: -20px;
    cursor: pointer;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 20px;
  }
`

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
export const StyledText = styled(Text)`


`