import styled from '@emotion/styled'

export const ScrollToTopContainer = styled.div`
  position: absolute;
  top: -90px;
  right: 0;
  z-index: 5;
  cursor: pointer;
  width: 90px;
  height: 90px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 70px;
    height: 70px;
    top: -70px;
  }
`
