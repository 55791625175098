import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  LegalFooterStyled,
  LegalFooterLinks,
  StyledText,
  CopyrightLeftContainer,
  CopyrightRightContainer,
} from './styles'
import LinkFormatter from 'components/LinkFormatter'

const LegalFooter = ({ query }) => {
  return (
    <LegalFooterStyled>
      <CopyrightLeftContainer>
        {query?.copyright_text?.[0]?.copyright?.text ?
        <StyledText as="p" type="primary-small">
          {query?.copyright_text?.[0]?.copyright?.text}
        </StyledText>
        : <></>}
      </CopyrightLeftContainer>
      <LegalFooterLinks>
        {query?.legal_footer_items?.length > 0 &&
          query?.legal_footer_items?.map((item, index) => {
            return item?.legal_item_link?.uid &&
              item?.legal_item_label?.text ? (
              <LinkFormatter pageHandle={item?.legal_item_link?.uid} pageType={item?.page_type} key={index}>
                <StyledText as="p" type="primary-small">
                  {item?.legal_item_label?.text}
                </StyledText>
              </LinkFormatter>
            ) : (
              <></>
            )
          })}
      </LegalFooterLinks>
      <CopyrightRightContainer>
        {query?.copyright_text?.[1]?.copyright?.text ?
        <StyledText as="p" type="primary-small">
          {query?.copyright_text?.[1]?.copyright?.text}
        </StyledText>
        : <></>}
      </CopyrightRightContainer>
    </LegalFooterStyled>
  )
}

LegalFooter.propTypes = {
  query: PropTypes.object.isRequired,
}

export default LegalFooter
