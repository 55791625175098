import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'

export const FooterContainerMain = styled.div`
  padding: 150px 20px 0;
  max-width: 2000px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding: 200px 250px 100px;
    max-width: 563px;
    padding-left: 0;
    padding-right: 0;
    transform: translateX(137px);
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    padding: 200px 200px 100px;
    padding-left: 0;
    padding-right: 0;
  }
`

export const FooterColumn = styled.div`
  min-width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    max-height: 185px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    max-width: 530px;
  }

  #label-email-e89376d7-d049-4a93-9a27-7854ef04d7e8 > span.hs-form-required {
    display: none !important;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
`

export const FooterItemBody = styled(Title)`
  color: ${props => props.theme.colors.lightblue};
  margin-bottom: 20px;
  font-weight: 100;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 50px;
    margin-bottom: 35px;
    font-size: ${props => props.theme.fonts.xl};
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    margin-bottom: 35px;
  }
`

export const FooterNewsletterInput = styled.div`
  max-width: 300px;
  max-height: 100px;
  margin-bottom: 20px;
`
