import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getQuery } from 'functions/getQuery'
import Icon from 'components/Icon'
import {
  StyledInputContainer,
  InputContainer,
  IconContainer,
  StyledText,
} from './styles'

const NewsletterForm = ({ query }) => {
  const [email, setEmail] = useState('')
  const [formSuccess, setFormSuccess] = useState(false)
  const inputQuery = getQuery(['document', 'data'], query)
  const [placeholder, setPlaceholder] = useState(
    inputQuery?.input_placeholder?.text
  )

  const submitHandler = e => {
    e.preventDefault()
    var request = new XMLHttpRequest()
    var url =
      'https://api.hsforms.com/submissions/v3/integration/submit/6622300/e89376d7-d049-4a93-9a27-7854ef04d7e8'
    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email,
        },
      ],
    }
    var final_data = JSON.stringify(data)
    request.open('POST', url)
    request.setRequestHeader('Content-Type', 'application/json')
    request.onreadystatechange = function() {
      if (request.readyState == 4 && request.status == 200) {
        setPlaceholder(inputQuery?.input_placeholder?.text)
        setFormSuccess(true)
      } else if (
        (request.readyState == 4 && request.status == 400) ||
        (request.readyState == 4 && request.status == 403) ||
        (request.readyState == 4 && request.status == 404)
      ) {
        alert(request.responseText) // Returns a 400 error the submission is rejected.
      }
    }
    // Sends the request
    request.send(final_data)
  }

  useEffect(() => {
    if (formSuccess) {
      setTimeout(() => {
        setEmail('')
        setFormSuccess(false)
      }, 1000)
    }
  }, [formSuccess])

  return (
    <>
      <InputContainer onSubmit={submitHandler} method="POST">
        <StyledInputContainer
          type="email"
          id="email"
          name="email"
          value={email}
          required="required"
          onChange={e => setEmail(e.target.value)}
          placeholder={placeholder}
        />
        <IconContainer onClick={submitHandler}>
          <Icon type="arrow-right" className="arrow-right" />
        </IconContainer>
      </InputContainer>
      {formSuccess ? (
        <StyledText whiteText type="super-small">
          {inputQuery?.input_success_message?.text}
        </StyledText>
      ) : (
        <></>
      )}
    </>
  )
}

NewsletterForm.propTypes = {
  query: PropTypes.object.isRequired,
}

export default NewsletterForm
